import React from 'react'
import useI18n from 'hooks/useI18n'
import { Text } from '@pancakeswap-libs/uikit'

export interface TranslatedTextProps {
  translationId: number
  children: string
}

const TranslatedText = ({ translationId, children }: TranslatedTextProps) => {
  const TranslateString = useI18n()
  return <Text>{TranslateString(translationId, children)}</Text>
}

export default TranslatedText
